import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '../typography/Typography';
import { Link } from '../link/Link';
import useControlled from '../private/hooks/useControlled';

function labelDisplayedRows({ to, count }) {
  return `Viewing ${to} of ${count} rows`;
}

/**
 * Renders a `Link` component to show all remaning, non-visible rows in a [`Table`](#table) component.
 *
 * Related components:
 * [TableFooterPagination](#tablefooterpagination), [TableRow](#tablerow), [TableCell](#tablecell),
 * [IconButton](#iconbutton), [Dropdown](#dropdown), [Link](#link)
 *
 *
 * Usage:
 *
 * ```jsx
 * import { TableFooterShowMore } from '@one-thd/sui-atomic-components';
 * ```
 */
const TableFooterShowMore = React.forwardRef((props, ref) => {
  const {
    count,
    rowsPerPage: rowsPerPageProp = 10,
    rowsPerPageValue,
    onRowsPerPageChange,
    ...other
  } = props;

  const page = 0;
  const rowsPerPageOption = rowsPerPageProp;

  const [rowsPerPage, setRowsPerPage] = useControlled({
    controlled: rowsPerPageValue,
    defaultValue: rowsPerPageOption
  });

  const handleOnToggle = (event) => {
    let rows;
    if (rowsPerPage === -1) {
      rows = rowsPerPageOption;
      // switching to show less
      setRowsPerPage(rowsPerPageOption);
    } else {
      rows = count;
      // switching to show all
      setRowsPerPage(-1);
    }
    if (onRowsPerPageChange) {
      onRowsPerPageChange(event, rows);
    }
  };

  const getLabelDisplayedRowsTo = () => {
    if (count === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1 ? count : Math.min(count, (page + 1) * rowsPerPage);
  };

  const getLabelDisplayedRowsCount = () => {
    return count === -1 ? -1 : count;
  };

  return (
    <div className="sui-flex sui-gap-2 md:sui-justify-center" {...other} ref={ref}>
      {rowsPerPage === -1 ? (
        <Link component="button" onClick={handleOnToggle} value={rowsPerPageOption}>
          View Less
        </Link>
      ) : (
        <Link component="button" onClick={handleOnToggle} value="-1">
          View All
        </Link>
      )}
      <Typography color="subtle">
        {labelDisplayedRows({
          to: getLabelDisplayedRowsTo(),
          count: getLabelDisplayedRowsCount(),
          page,
        })}
      </Typography>
    </div>
  );

});

TableFooterShowMore.displayName = 'TableFooterShowMore';

TableFooterShowMore.propTypes = {
  /**
   * The total number of rows.
   */
  count: PropTypes.number.isRequired,
  /**
   * Customizes the option of the number of rows to show when not showing all.
   *
   * Sets the default value of the rows per page if not controlled.
   * @default 10
   */
  rowsPerPage: PropTypes.number,
  /**
   * The number of rows per page. Use to control the component.
   *
   * Set -1 to display all the rows.
   */
  rowsPerPageValue: PropTypes.number,
  /**
   * Callback fired when the number of rows per page is changed.
   */
  onRowsPerPageChange: PropTypes.func,
};

export { TableFooterShowMore };