import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Button, IconButton, Typography } from '@one-thd/sui-atomic-components';
import {
  filterUnlinkedAccounts,
  sortAccountsForDefault,
  getUpdatedAccountList,
  getAccountToBeUpdated,
} from './cardholder-utils';
import UnlinkedAlert from './unlinked-alert.component';
import AccountManagement from './account-management/account-management.component';
import AccountSummary from './account-summary/account-summary.component';
import AdditionalServices from './additional-services/additional-services.component';
import ChildAccounts from './child-accounts/child-accounts.component';
import UnlinkAccount from './unlink-account/unlink-account.component';
import ErrorAlert from './error-alert.component';
import { MY_ACCOUNT_DISCLAIMER, PROCESS_INDICATORS, PLCN_HOMEDEPOT } from '../utils/constants';
import { SpecialSavings } from '~/@one-thd/sui-icons';
import * as constants from '../utils/constants';

export const CardholderView = ({
  accounts,
  linkAccount,
  unlinkAccount,
  redirectToCiti,
  updateAccountsForChildAccounts,
  isSaveCardButtonEnabled,
}) => {
  const experienceContext = useContext(ExperienceContext);
  const { channel } = experienceContext;
  const isMobile = channel === 'mobile';

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [sortedAccounts, setSortedAccounts] = useState(accounts);
  const [unlinkedAccounts, setUlinkedAccounts] = useState(null);
  const [error, setError] = useState(null);

  const updateAccountState = (updatedAccount, updatedAccounts) => {
    setSelectedAccount(updatedAccount);
    setSortedAccounts(updatedAccounts);
    if (updatedAccount?.errors?.errors) {
      setError(updatedAccount.errors.errors[0]);
    } else {
      setError(null);
    }
  };

  const updateSelectedAccount = (updatedAccount, updatedAccounts) => {
    let account = updatedAccount;
    let accountsList = updatedAccounts || sortedAccounts;
    const noCreditLimit = account && account.accountId && !account.creditLimit;
    const childAccountsToBeUpdated = account && account.parentAccountIndicator === 'Y' && !account.childAccounts;
    if (noCreditLimit || childAccountsToBeUpdated) {
      updateAccountsForChildAccounts(account)
        .then((data) => {
          accountsList = getUpdatedAccountList(data, accountsList);
          account = getAccountToBeUpdated(data);
          updateAccountState(account, accountsList);
        });
    } else {
      updateAccountState(account, accountsList);
    }
  };

  const sortAccounts = () => {
    if (accounts && accounts.length > 0) {
      let [accountsList, unlinked] = filterUnlinkedAccounts(accounts);
      let [selected] = accountsList.sort(sortAccountsForDefault);
      updateSelectedAccount(selected, accountsList);
      setUlinkedAccounts(unlinked);
    }
  };

  useEffect(() => {
    sortAccounts();
  }, [accounts]);

  if (!selectedAccount) {
    return null;
  }

  const specialOffers = () => {
    redirectToCiti(PROCESS_INDICATORS.SPECIAL_OFFERS, 'plcc:special offers');
  };

  const redirectWithAccountId = (citiPageIndicator, analyticsTag, additionalParam = null, childAccountId = null) => {
    let accountId = ((selectedAccount.accountType === constants.PLNP_HOMEDEPOT
        || selectedAccount.accountType === constants.PLCR_HOMEDEPOT)
      && selectedAccount.accountId) ? selectedAccount.accountId : '';
    accountId = childAccountId || accountId;
    redirectToCiti(citiPageIndicator, analyticsTag, selectedAccount.accountType, accountId, additionalParam);
  };

  return (
    <div data-component="CardholderView">
      <UnlinkedAlert
        unlinkedAccounts={unlinkedAccounts}
        redirectToCiti={redirectWithAccountId}
        linkAccount={linkAccount}
      />
      {error !== null
        && (
          <ErrorAlert
            redirectToCiti={redirectWithAccountId}
            error={error}
            accountType={selectedAccount.accountType}
          />
        )}
      {selectedAccount?.accountType === PLCN_HOMEDEPOT && !error
        && (
          <Button startIcon={SpecialSavings} variant="ghost" onClick={specialOffers}>
            View Special Offers and Coupons
          </Button>
        )}
      <AccountManagement
        accounts={sortedAccounts}
        linkAnotherCard={linkAccount}
        changeAccountHandler={updateSelectedAccount}
      />
      <AccountSummary
        account={selectedAccount}
        redirectToCiti={redirectWithAccountId}
        errorState={error !== null}
        isMobile={isMobile}
      />
      {selectedAccount?.childAccounts?.length
        && (
          <ChildAccounts
            childAccounts={selectedAccount?.childAccounts || []}
            redirectToCiti={redirectWithAccountId}
            errorState={error !== null}
            isMobile={isMobile}
          />
        )}
      <AdditionalServices
        redirectToCiti={redirectWithAccountId}
        accountType={selectedAccount.accountType}
        isSaveCardButtonEnabled={isSaveCardButtonEnabled}
        isMobile={isMobile}
        errorState={error !== null}
      />
      <UnlinkAccount
        accountType={selectedAccount.accountType}
        unlinkAccount={unlinkAccount}
        isMobile={isMobile}
      />
      <Typography color="subtle" variant="body-xs" lineClamp="none">
        <div className="sui-my-10">{MY_ACCOUNT_DISCLAIMER}</div>
      </Typography>
    </div>
  );
};

CardholderView.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountType: PropTypes.string,
      balance: PropTypes.string,
      minimumDue: PropTypes.string,
      dueDate: PropTypes.string,
      currentBalance: PropTypes.string,
      availableCredit: PropTypes.string,
      creditLimit: PropTypes.string,
      totalOpenInvoiceCount: PropTypes.string,
    })
  ).isRequired,
  linkAccount: PropTypes.func.isRequired,
  unlinkAccount: PropTypes.func.isRequired,
  redirectToCiti: PropTypes.func.isRequired,
  updateAccountsForChildAccounts: PropTypes.func.isRequired,
  isSaveCardButtonEnabled: PropTypes.bool,
};

CardholderView.defaultProps = {
  isSaveCardButtonEnabled: true,
};
