import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem, Link } from '@one-thd/sui-atomic-components';
import { Add, CreditCard } from '@one-thd/sui-icons';
import * as utils from '../cardholder-utils';
import AllLinkedCardsModal from '../all-linked-cards-modal/all-linked-cards-modal.component';

const AccountManagement = ({ accounts, linkAnotherCard, changeAccountHandler }) => {
  const [openAccountSelector, setOpenAccountSelector] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(accounts[0]);
  const accountsSorted = utils.prioritizeAndAlphabeticallySortCards(accounts);

  // eslint-disable-next-line
  const dropDownStyle = `sui-group sui-inline-flex sui-items-center sui-px-3 sui-py-2 sui-justify-between
sui-font-regular sui-text-base sui-leading-tight sui-w-full sui-rounded-base
sui-border-solid sui-border-1 focus-visible:sui-text-inverse focus-visible:sui-bg-inverse
focus-visible:sui-outline focus-visible:sui-outline-4 focus-visible:sui-outline-input-focus
sui-border-strong hover:sui-border-input-hover sui-text-primary`;

  const dropDownChange = (clonedEvent, newValue, child) => {
    changeAccountHandler(accountsSorted[newValue]);
    setSelectedAccount(accountsSorted[newValue]);
  };

  const modalChange = (newAccount) => {
    changeAccountHandler(newAccount, accounts);
    setSelectedAccount(newAccount);
  };

  const getSelectedIndex = (accountList, currentAccount) => {
    return accountList.findIndex((account) => account.accountNumber === currentAccount.accountNumber);
  };

  const validAccounts = accounts && accounts.length > 0;
  if (!validAccounts) {
    return null;
  }

  const dropdownAccount = (account, index) => {
    return (
      <MenuItem
        value={index}
        key={index + (account?.accountNumber || '') + (account?.accountId || '')}
      >
        <div className="sui-flex sui-items-center">{utils.renderAccount(account)}</div>
      </MenuItem>
    );
  };

  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <>
      <div className="sui-w-full sui-mb-3">
        <div>Account Selected</div>
        {accountsSorted?.length <= 10 ? (
          <div className="sui-w-full sui-h-15">
            <Dropdown
              maxItems={10}
              className={dropDownStyle}
              value={getSelectedIndex(accountsSorted, selectedAccount) > -1
                ? getSelectedIndex(accountsSorted, selectedAccount) : 0}
              onChange={dropDownChange}
            >
              {accountsSorted.map((account, index) => {
                return dropdownAccount(account, index);
              })}
            </Dropdown>
          </div>
        ) : (
          <div className="sui-w-full sui-h-15
            sui-border-button-inactive sui-border-solid sui-border-t-1 sui-border-b-1"
          >
            {utils.renderAccount(selectedAccount)}
          </div>
        )}
        {accountsSorted?.length > 10 && (
          <div className="sui-flex sui-items-center sui-p-3 sui-pb-2">
            <CreditCard size="small" />
            <div className="sui-pl-2">
              <Link onClick={() => setOpenAccountSelector(true)}>View All Linked Cards</Link>
            </div>
          </div>
        )}
        <div className="sui-flex sui-items-center sui-p-3 sui-pb-8">
          <Add size="small" />
          <div className="sui-pl-2">
            <Link onClick={linkAnotherCard}>Link Another Card</Link>
          </div>
        </div>
      </div>

      <AllLinkedCardsModal
        accounts={accountsSorted}
        changeAccountHandler={modalChange}
        open={openAccountSelector}
        handleClose={() => setOpenAccountSelector(false)}
      />
    </>
  );
};

AccountManagement.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountName: PropTypes.string,
      accountNumber: PropTypes.string,
      accountType: PropTypes.string,
      displayAccountNumber: PropTypes.string,
      parentAccountIndicator: PropTypes.string,
      accountId: PropTypes.string,
    })
  ).isRequired,
  linkAnotherCard: PropTypes.func.isRequired,
  changeAccountHandler: PropTypes.func.isRequired,
};

export default AccountManagement;
