import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { RightRailMessage } from './rightRailMessage/rightRailMessage.component';
import { CARD_IMAGE } from '../utils/constants';
import { getDefaultApplyNowURL, loadEstimatorScript } from '../utils/payment-estimator-utils';
import { clickEvent } from '../utils/publisher';
import TermsAndConditionsDrawer from '../common/terms-and-conditions-drawer.component';
import { getDynamicOffer, formatNumber } from '../utils/general';

export const PLCCB2CRightRailMessage = (props) => {
  const { plccEligibleTotal, total } = props;

  const cardImage = CARD_IMAGE.PLCN_HOMEDEPOT;
  const experienceContext = useContext(ExperienceContext);
  const paymentEstimatorVersion = useConfigService('PaymentEstimatorVersion') || 'v1.2.0';
  const discountPercent = getDynamicOffer(plccEligibleTotal);

  const [applyNowUrl, setApplyNowUrl] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);

  const {
    channel
  } = experienceContext;
  const isMobile = channel === 'mobile';

  useEffect(() => {
    const loadPaymentEstimator = () => {
      setApplyNowUrl(getDefaultApplyNowURL(isMobile, 'opc', false));
    };
    loadEstimatorScript(paymentEstimatorVersion, loadPaymentEstimator, isMobile);
  }, [total]);

  const analyticEvent = () => {
    clickEvent('plcc right rail message', 'plcc-opc');
  };

  const getDescription = () => {
    return (
      <>
        <span className="sui-font-w-bold">
          GET ${discountPercent} OFF* and pay ${formatNumber(total - discountPercent)}
        </span>{' '}
        instead on your qualifying purchase, plus receive 6 months everyday financing** on purchases
        over $299 when you open a new card
      </>
    );
  };

  const getApplyNowLink = () => {
    return applyNowUrl + `&saleAmt=${total}` || '';
  };

  if (plccEligibleTotal < 25) return null;

  return (
    <>
      <TermsAndConditionsDrawer
        closeDrawer={() => setShowDrawer(false)}
        open={showDrawer}
        isB2B={false}
        showFinancing
        applyNowClick={() => clickEvent('plcc-est')}
        price={total}
      />
      <RightRailMessage
        cardImage={cardImage}
        description={getDescription()}
        applyNow={getApplyNowLink()}
        viewDetails={() => setShowDrawer(true)}
        applyClick={analyticEvent}
      />
    </>
  );
};
PLCCB2CRightRailMessage.propTypes = {
  plccEligibleTotal: PropTypes.number,
  total: PropTypes.number
};
PLCCB2CRightRailMessage.defaultProps = {
  plccEligibleTotal: 0,
  total: 0
};
