import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SaveCardSuccessModal } from './save-card-success-modal.component';
import { SaveCardFailureModal } from './save-card-failure-modal-component';

export const SaveCardModal = ({ openAddCard }) => {
  const [showModal, setShowModal] = useState(true);
  const [saveCardParam, setSaveCardParam] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setSaveCardParam(urlParams.get('saveCard'));
  }, []);

  const closeModalHandler = () => {
    setShowModal(false);
  };

  if (showModal && saveCardParam === 'success') {
    return (
      <SaveCardSuccessModal id="modal-ui" closeModal={closeModalHandler} />
    );
  } if (showModal && saveCardParam === 'fail') {
    return (
      <SaveCardFailureModal id="modal-ui" openAddCardModal={openAddCard} closeModal={closeModalHandler} />
    );
  }

  return null;
};

SaveCardModal.propTypes = {
  openAddCard: PropTypes.func.isRequired
};
