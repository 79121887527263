import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Typography, Link } from '@one-thd/sui-atomic-components';
import { UNLINKED_CARD_TEXT } from '../utils/constants';

const UnlinkedAlert = ({ redirectToCiti, linkAccount, unlinkedAccounts }) => {
  const [showMessage, setShowMessage] = useState(true);
  if (!showMessage || !unlinkedAccounts || unlinkedAccounts.length < 1) {
    return null;
  }
  const unlinkedAccountNames = unlinkedAccounts.map((account) => UNLINKED_CARD_TEXT[account.accountType]);
  const description = (unlinkedAccountNames.length === 1)
    ? 'Your ' + unlinkedAccountNames[0] + ' Account was unlinked due to inactivity.'
    : 'Your ' + unlinkedAccountNames.join(' and ') + ' Accounts were unlinked due to inactivity.';

  const redirect = () => {
    setShowMessage(false);
    return (unlinkedAccounts.length > 1)
      ? linkAccount()
      : redirectToCiti('', '');
  };

  return (
    <Alert status="info" onClose={() => setShowMessage(false)}>
      <div className="sui-flex sui-gap-1 sui-flex-wrap">
        <Typography>{description}</Typography>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link onClick={redirect} target="_blank">
          Re-link Accounts
        </Link>
      </div>
    </Alert>
  );
};

UnlinkedAlert.propTypes = {
  redirectToCiti: PropTypes.func.isRequired,
  linkAccount: PropTypes.func.isRequired,
  unlinkedAccounts: PropTypes.arrayOf(PropTypes.shape({
    accountType: PropTypes.string
  }))
};

UnlinkedAlert.defaultProps = {
  unlinkedAccounts: []
};

export default UnlinkedAlert;
