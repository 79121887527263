/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Drawer, DrawerHeader, DrawerBody, DrawerFooter, Button
} from '@one-thd/sui-atomic-components';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { CARD_IMAGE } from '../utils/constants';
import { getPaymentEstimatorValues, loadEstimatorScript } from '../utils/payment-estimator-utils';
import { financingDetails, financingTerms, suggestedPaymentDetails } from '../utils/terms-and-conditions-utils';

const TermsAndConditionsDrawerPlccPrompt = ({
  closeDrawer,
  open,
  isB2B,
  isAppliancePromo,
  isTieredPromo,
  paymentEstimatorLoaded,
  saleAmount,
}) => {
  const [paymentEstimatorValues, setPaymentEstimatorValues] = useState({});
  const paymentEstimatorVersion = useConfigService('PaymentEstimatorVersion') || 'v1.5.0';
  const plccPromptInterestRate = (useConfigService('plccPromptInterestRate')) || '17.99% - 29.99%';
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  useEffect(() => {
    loadEstimatorScript(
      paymentEstimatorVersion,
      () => setPaymentEstimatorValues(getPaymentEstimatorValues(saleAmount, isAppliancePromo, isTieredPromo)),
      channel === 'mobile'
    );
  }, [paymentEstimatorLoaded]);

  const {
    financingMonths, promoStartDate, promoEndDate,
    interestRate, suggestedAmount, showFinancing
  } = paymentEstimatorValues;

  return (
    <>
      {paymentEstimatorLoaded ? (
        <Drawer
          open={open}
          onClose={closeDrawer}
          position="right"
          id="drawer-terms-conditions-plcc-prompt"
          data-testid="terms-conditions-plcc-prompt"
        >
          <DrawerHeader title="Enjoy Special Savings With Your Home Depot Consumer Credit Card" onClose={closeDrawer} className="sui-pb-0" />
          <div className="sui-flex sui-flex-col sui-justify-between sui-h-full">
            <DrawerBody>
              <div className="sui-flex sui-flex-col sui-gap-4">
                <div className="sui-m-auto sui-px-6 sui-max-w-xs">
                  <img
                    src={isB2B ? CARD_IMAGE.PLCR_HOMEDEPOT : CARD_IMAGE.PLCN_HOMEDEPOT}
                    alt="Credit Card Icon"
                    width="185"
                    height="103"
                  />
                </div>
                <div className="sui-flex sui-flex-col sui-gap-3">

                  {suggestedPaymentDetails(suggestedAmount, saleAmount, financingMonths)}
                  {financingDetails(isTieredPromo, isAppliancePromo, financingMonths, promoStartDate, promoEndDate, saleAmount)}
                </div>
                <div className="sui-grow">
                  {financingTerms(plccPromptInterestRate, saleAmount, true)}
                </div>
              </div>
            </DrawerBody>
            <DrawerFooter>
              <Button
                data-testid="close-button"
                onClick={closeDrawer}
                fullWidth
              >
                Close
              </Button>
            </DrawerFooter>
          </div>
        </Drawer>
      ) : null}
    </>
  );
};

TermsAndConditionsDrawerPlccPrompt.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool,
  isB2B: PropTypes.bool,
  isAppliancePromo: PropTypes.bool,
  isTieredPromo: PropTypes.bool,
  paymentEstimatorLoaded: PropTypes.bool.isRequired,
  saleAmount: PropTypes.number.isRequired,
};

TermsAndConditionsDrawerPlccPrompt.defaultProps = {
  open: true,
  isB2B: false,
  isAppliancePromo: false,
  isTieredPromo: false,
};

export default TermsAndConditionsDrawerPlccPrompt;
