/* eslint-disable @mizdra/layout-shift/require-size-attributes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; import {
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Collapse,
  TableFooter,
  TableFooterShowMore,
  Link
} from '@one-thd/sui-atomic-components';
import { Search, Settings, ArrowDown, ArrowUp, } from '@one-thd/sui-icons';
import { PROCESS_INDICATORS } from '../../utils/constants';
import CurrentBalance from '../account-summary/current-balance.component';

const DEFAULT_ROWS_PER_PAGE = 10;

const ChildAccounts = ({ childAccounts, redirectToCiti, errorState, isMobile }) => {

  const [selected, setSelected] = React.useState(null);
  const [searchApplied, setSearchApplied] = useState(null);
  const [childAccountList, setchildAccountList] = useState(childAccounts);
  const [displayAccountList, setDisplayAccountList] = useState(childAccounts);

  const filterAccounts = (event) => {
    const searchTerm = event.target.value;
    setSearchApplied(searchTerm);
    const accounts = JSON.parse(JSON.stringify(childAccounts));
    const filteredAccountsSearchTerm = accounts.filter(
      (account) => {
        const { accountNumber, accountName } = account;
        return (
          accountNumber?.includes(searchTerm?.toLowerCase())
          || accountName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
      }
    );
    if (searchTerm) {
      setDisplayAccountList(filteredAccountsSearchTerm);
    } else {
      setDisplayAccountList(filteredAccountsSearchTerm.slice(0, DEFAULT_ROWS_PER_PAGE));
    }
  };

  const onRowsPerPageChangeHandler = (event, rows) => {
    setDisplayAccountList(childAccountList.slice(0, rows));
  };

  const invoiceButton = (accountType, accountId) => {
    return (
      <Link
        onClick={() => redirectToCiti(
          PROCESS_INDICATORS.VIEW_INVOICES,
          null,
          null,
          accountId
        )}
      >Invoices
      </Link>
    );
  };

  const manageBuyers = (accountType, accountId) => {
    return (
      <Link
        onClick={() => redirectToCiti(
          PROCESS_INDICATORS.MANAGE_AUTH_BUYERS,
          null,
          null,
          accountId
        )}
      >Manage Buyers
      </Link>
    );
  };

  useEffect(() => {
    setchildAccountList(childAccounts);
    setDisplayAccountList(childAccounts.slice(0, DEFAULT_ROWS_PER_PAGE));
  }, [childAccounts]);

  const handleRowClick = (id) => {
    setSelected(selected === id ? null : id);
  };
  const label = { inputProps: { 'aria-label': 'description' } };
  return (
    <>
      <div className="sui-mb-6 sui-mt-5">
        <Typography className="sui-header" variant="h3" weight="display">Child Accounts</Typography>
      </div>
      <div className={isMobile ? 'sui-flex sui-flex-col-reverse sui-w-full sui-mb-4' : 'sui-w-full'}>
        <div className="sui-grid sui-grid-cols-1 md:sui-grid-cols-2 lg:sui-grid-cols-2 sui-gap-8 sui-w-full">
          <TextField
            fullWidth
            placeholder="Filter by Nickname or Last 4 Digits"
            onChange={filterAccounts}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="subtle" />
                </InputAdornment>
              ),
              ...label
            }}
          />
        </div>
      </div>
      <div className="sui-flex sui-flex-col sui-w-full sui-gap-4 sui-mt-4">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              {!isMobile && (<TableCell>Account Name</TableCell>)}
              {!isMobile && (<TableCell>Account Number</TableCell>)}
              {isMobile && (<TableCell>Account</TableCell>)}
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayAccountList.map(({ accountName, accountNumber, accountId, accountType }, index) => (
              <React.Fragment key={accountId}>
                <TableRow
                  onClick={() => handleRowClick(accountId)}
                  /* Last row has rounded bottom corners, but that row could be collapsed */
                  lastVisible={index === displayAccountList.length - 1 && selected !== accountId}
                >
                  <TableCell padding="control">
                    <IconButton
                      buttonSize="tight"
                      aria-label={selected === accountId ? 'iconButtonArrowUp' + index : 'iconButtonArrowDown' + index}
                      icon={selected === accountId ? ArrowUp : ArrowDown}
                    />
                  </TableCell>
                  {!isMobile && (
                    <>
                      <TableCell><span data-testid={'accountName' + index}>{accountName || ''} </span></TableCell>
                      <TableCell>
                        <span data-testid={'accountNumber' + index}>
                          {accountNumber ? '**** ' + accountNumber : ''}
                        </span>
                      </TableCell>
                    </>
                  )}
                  {isMobile && (
                    <TableCell>
                      <div data-testid={'accountName' + index}>{accountName || ''} </div>
                      <div data-testid={'accountNumber' + index}>{accountNumber ? '**** ' + accountNumber : ''}</div>
                    </TableCell>
                  )}
                  <TableCell>
                    {invoiceButton(accountType, accountId)}
                  </TableCell>
                  <TableCell>
                    {manageBuyers(accountType, accountId)}
                  </TableCell>
                </TableRow>
                <TableRow collapsed={selected !== accountId}>
                  <TableCell colSpan={5} padding="none">
                    <Collapse in={selected === accountId}>
                      <div
                        className="sui-grid sui-p-8 sui-grid-cols-1 sui-gap-y-2 sui-table-cell-base"
                        data-testid="subrow"
                      >
                        <CurrentBalance account={displayAccountList[index]} />
                      </div>
                    </Collapse>
                  </TableCell>
                </TableRow>
                <TableRow />
              </React.Fragment>
            ))}
          </TableBody>
          <TableFooter colSpan="5">
            {childAccountList.length >= DEFAULT_ROWS_PER_PAGE && !searchApplied ? (
              <TableFooterShowMore
                rowsPerPage={DEFAULT_ROWS_PER_PAGE}
                onRowsPerPageChange={onRowsPerPageChangeHandler}
                count={childAccountList.length}
              />
            ) : null}
          </TableFooter>
        </Table>
      </div>
    </>
  );

};

ChildAccounts.propTypes = {
  redirectToCiti: PropTypes.func,
  errorState: PropTypes.bool,
  isMobile: PropTypes.bool,
  childAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountType: PropTypes.string,
      accountId: PropTypes.string,
      accountNumber: PropTypes.string,
      accountName: PropTypes.string,
      parentAccountIndicator: PropTypes.string,
      balance: PropTypes.string,
      currentBalance: PropTypes.string,
      availableCredit: PropTypes.string,
      creditLimit: PropTypes.string,
    })
  ),
};

ChildAccounts.defaultProps = {
  redirectToCiti: () => {},
  errorState: false,
  isMobile: false,
  childAccounts: [
    {
      accountType: 'PLCN_HOMEDEPOT',
      accountId: '',
      accountNumber: '',
      accountName: '',
      parentAccountIndicator: 'N',
      balance: '',
      currentBalance: '',
      availableCredit: '',
      creditLimit: '',
    },
  ],
};

export default ChildAccounts;
