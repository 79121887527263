import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from './TableCell';
import { TableRow } from './TableRow';

/**
 * Container to display controls used to paginate over the rows of a [`Table`](#table) component.
 *
 * It renders a `TableRow` with a single `TableCell` inside that requires the `colSpan` prop to be provided
 * for it to span across all table columns. You can override this behavior and render children within a simple
 * container by setting the `component` prop.
 *
 * Related components:
 * [TableFooterShowMore](#tablefootershowmore), [TableFooterPagination](#tablefooterpagination)
 * [TableRow](#tablerow), [TableCell](#tablecell).
 *
 * Usage:
 *
 * ```jsx
 * import { TableFooter } from '@one-thd/sui-atomic-components';
 * ```
 */
const TableFooter = React.forwardRef((props, ref) => {

  const {
    component = 'tfoot',
    children,
    colSpan,
    ...other
  } = props;

  const Root = component;

  return (
    <>
      {component === 'tfoot' ? (
        <Root ref={ref} {...other}>
          <TableRow lastVisible>
            <TableCell colSpan={colSpan}>
              {children}
            </TableCell>
          </TableRow>
        </Root>
      ) : (
        <Root className="sui-bg-primary sui-border-solid sui-border-x-1 sui-border-b-1 sui-border-primary sui-px-4 sui-py-2">
          {children}
        </Root>
      )}
    </>
  );
});

TableFooter.displayName = 'TableFooter';

TableFooter.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   */
  component: PropTypes.elementType,
  /**
   * The number of columns to span the internal `TableCell`
   */
  colSpan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export { TableFooter };