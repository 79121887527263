import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from '@one-thd/sui-atomic-components';
import { InfoToolTip } from './info-tool-tip.component';
import { TOOL_TIP_TEXT } from '../../utils/constants';
import { formatCurrency } from '../cardholder-utils';

const calculatePercentCreditUsed = (currentBalance, creditLimit) => {
  if (!currentBalance || !creditLimit) {
    return 0;
  }
  const currentBalanceValue = parseFloat(currentBalance?.replace(',', ''));
  const creditLimitValue = parseFloat(creditLimit?.replace(',', ''));
  return creditLimitValue <= 0 ? creditLimitValue : (currentBalanceValue * 100) / creditLimitValue;
};

const printCurrencyValue = (amount, isInfo = false) => {
  if (!amount) {
    return <div>---</div>;
  }
  const start = amount.slice(0, 1) === '-' ? '-$' : '$';
  const middle = Math.abs(Math.trunc(Number(amount.replace(',', '')))).toLocaleString();
  const decimal = amount.indexOf('.');
  const end = decimal > -1 ? amount.slice(decimal + 1) : '00';

  const printSpan = (text) => (
    <span
      className={`sui-font-display sui-pt-1 sui-pr-2px sui-text-base ${isInfo && 'sui-text-info'}`}
    >
      {text}
    </span>
  );

  return (
    <div className="sui-flex sui-align-top">
      {printSpan(start)}
      <span className={`sui-font-display sui-text-3xl ${isInfo && 'sui-text-info'}`}>{middle}</span>
      {printSpan(end)}
    </div>
  );
};

const printCurrencyLabel = (title, toolTipMessage, isInfo = false) => {
  return (
    <div className="sui-flex">
      <p className={`sui-pt-1 ${isInfo ? 'sui-text-info' : ''} sui-font-regular sui-text-base`}>
        {title}
      </p>
      <InfoToolTip message={toolTipMessage} title={title} />
    </div>
  );
};

const CurrentBalance = ({ account }) => {
  const { currentBalance, availableCredit, creditLimit } = account;

  const percentCreditUsed = calculatePercentCreditUsed(currentBalance, creditLimit);

  return (
    <div className="sui-flex sui-items-center sui-flex-col">
      <div className="sui-flex sui-w-full sui-justify-between">
        {printCurrencyValue(currentBalance, true)}
        {printCurrencyValue(availableCredit)}
      </div>
      <div className="sui-w-full">
        <ProgressBar
          value={percentCreditUsed}
          startLabel={printCurrencyLabel('Current Balance', TOOL_TIP_TEXT.CURRENT_BALANCE, true)}
          endLabel={printCurrencyLabel('Available Credit', TOOL_TIP_TEXT.AVAILABLE_CREDIT)}
          labelPlacement="bottom"
        />
      </div>
      <p className="sui-m-6 sui-text-subtle">Total Credit: {formatCurrency(creditLimit)}</p>
    </div>
  );
};

CurrentBalance.propTypes = {
  account: PropTypes.shape({
    accountType: PropTypes.string,
    currentBalance: PropTypes.string,
    availableCredit: PropTypes.string,
    creditLimit: PropTypes.string,
  }),
};
CurrentBalance.defaultProps = {
  account: {
    accountType: 'PLCN_HOMEDEPOT',
    currentBalance: null,
    availableCredit: null,
    creditLimit: null,
  },
};

export default CurrentBalance;
