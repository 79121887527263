/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link, Button } from '@one-thd/sui-atomic-components';
import PropTypes from 'prop-types';
import UnlinkAccountModal from './unlink-account-modal';
import { CARD_NAME } from '../../utils/constants';

const UnlinkAccount = ({ unlinkAccount, accountType, isMobile }) => {
  const [unlinkModalState, setUnlinkModalState] = useState(false);

  const unlinkAccountFunc = () => {
    setUnlinkModalState(false);
    return unlinkAccount(accountType);
  };

  return (
    <div data-testid="unlink-account-container" className={`sui-w-full ${isMobile ? 'sui-mt-10' : 'sui-mt-36'}`}>
      <div className="sui-text-subtle sui-pb-4 sui-text-base" data-testid="unlink-account-desc">
        {`Your Home Depot My Account is linked to your Home Depot ${CARD_NAME[accountType]} account.`}
      </div>
      <Link
        onClick={() => setUnlinkModalState(true)}
      >
        Unlink Accounts
      </Link>
      {unlinkModalState
        && (
          <UnlinkAccountModal
            modalClose={() => setUnlinkModalState(false)}
            unlinkAccount={unlinkAccountFunc}
            accountsType={accountType}
            isMobile={isMobile}
          />
        )}
    </div>
  );
};

UnlinkAccount.propTypes = {
  accountType: PropTypes.string.isRequired,
  unlinkAccount: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

UnlinkAccount.defaultProps = {
  isMobile: false
};

export default UnlinkAccount;
