import React, { useEffect } from 'react';
import './save-card-modal.style.scss';
import {
  Modal, ModalFooter, ModalBody, Button
} from '@one-thd/sui-atomic-components';
import PropTypes from 'prop-types';
import { CARD_IMAGE, CARD_IMAGE_TESTID } from '../utils/constants';
import { modalImpressionEvent } from '../utils/publisher';

/* eslint-disable @mizdra/layout-shift/require-size-attributes */
export const SaveCardFailureModal = ({
  openAddCardModal,
  closeModal,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const cardType = urlParams.get('cardType') || 'PLCN_HOMEDEPOT';

  const handleAddCardClick = () => {
    closeModal();
    openAddCardModal();
  };

  useEffect(() => {
    modalImpressionEvent('unable to save commercial card');
  }, []);

  return (
    <Modal
      closeButton
      open
      onClose={closeModal}
    >
      <ModalBody onClose={closeModal} />
      <ModalBody data-component="SaveCardFailureModal">
        <img
          data-testid={CARD_IMAGE_TESTID[cardType]}
          src={CARD_IMAGE[cardType]}
          alt="creditcard"
          className="save-card-modal-image"
        />
        <p className="sui-font-display sui-font-condensed sui-text-center sui-text-2xl">
          We&apos;re Having Trouble Saving Your Card
        </p>
      </ModalBody>
      <ModalFooter>
        <div className="sui-flex sui-justify-center sui-w-full sui-gap-4
        sui-pt-4 sui-flex-col sm:sui-flex-row save-card-modal-button-reverse"
        >
          <div className="sui-grow">
            <Button
              data-testid="closeModal"
              onClick={closeModal}
              variant="secondary"
              fullWidth
            >
              I&apos;ll add it later
            </Button>
          </div>
          <div className="sui-grow">
            <Button
              data-testid="openModal"
              onClick={handleAddCardClick}
              variant="primary"
              fullWidth
            >
              Manually Add Card
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

SaveCardFailureModal.propTypes = {
  openAddCardModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};
