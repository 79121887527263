import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from '@one-thd/sui-atomic-components';
import CurrentBalance from './current-balance.component';
import { formatCurrency } from '../cardholder-utils';
import { PROCESS_INDICATORS } from '../../utils/constants';

const AccountSummary = ({ account, redirectToCiti, errorState, isMobile }) => {
  const {
    accountType,
    balance,
    minimumDue,
    dueDate,
    totalOpenInvoiceCount,
    currentBalance,
    creditLimit
  } = account;

  const creditLimitReached = currentBalance && creditLimit
    ? parseFloat(currentBalance.replace(',', ''))
    >= parseFloat(creditLimit.replace(',', ''))
    : false;

  const makeAPayment = () => {
    redirectToCiti(PROCESS_INDICATORS.MAKE_A_PAYMENT, 'plcc:make a payment');
  };

  const viewStatements = () => {
    redirectToCiti(PROCESS_INDICATORS.VIEW_STATEMENTS, 'plcc:view statements');
  };

  const manageInvoices = () => {
    redirectToCiti(PROCESS_INDICATORS.VIEW_INVOICES, 'plcc:view invoices');
  };

  const buttonGroupClass = (isMobile || accountType === 'PLNP_HOMEDEPOT') ? ' sui-flex sui-gap-3 sui-flex-col-reverse'
    : 'sui-grid sui-grid-cols-1 md:sui-grid-cols-1 lg:sui-grid-cols-2 sui-gap-3';

  return (
    <div
      className="sui-grid sui-grid-cols-1 md:sui-grid-cols-2 lg:sui-grid-cols-2 sui-gap-8 sui-items-center sui-w-full"
    >
      <div>
        {creditLimitReached ? (
          <Alert status="error">
            <p className="sui-text-danger">Credit Limit Reached.</p>
            <p>You&apos;ve reached your Credit Limit of {formatCurrency(creditLimit)}</p>
          </Alert>
        ) : (
          <CurrentBalance account={account} />
        )}
      </div>
      <div className="sui-pt-2 sui-text-base">
        <div className="sui-pb-6">
          {accountType === 'PLNP_HOMEDEPOT' && (
            <div className="sui-flex sui-justify-between sui-font-regular sui-pt-3">
              <p className="sui-font-bold">Total Open Invoices</p>
              <p>{totalOpenInvoiceCount}</p>
            </div>
          )}
          <div className="sui-flex sui-justify-between sui-font-regular sui-pt-3">
            <p className="sui-font-bold">Statement Balance</p>
            <p>{formatCurrency(currentBalance || balance)}</p>
          </div>
          {accountType !== 'PLNP_HOMEDEPOT' && (
            <>
              <div className="sui-flex sui-justify-between sui-font-regular sui-pt-3">
                <p className="sui-font-bold">Minimum Payment Due</p>
                <p>{formatCurrency(minimumDue)}</p>
              </div>
              <div className="sui-flex sui-justify-between sui-font-regular sui-pt-3">
                <p className="sui-font-bold">Payment Due Date</p>
                <p>
                  {dueDate && dueDate}
                  {!dueDate && '---'}
                </p>
              </div>
            </>
          )}
        </div>

        <div className={buttonGroupClass}>
          {accountType === 'PLNP_HOMEDEPOT' ? (
            <Button onClick={manageInvoices} fullWidth variant="primary" disabled={errorState}>
              Manage Invoices
            </Button>
          ) : (
            <>
              <Button onClick={viewStatements} fullWidth variant="secondary" disabled={errorState}>
                View Statements
              </Button>
              <Button onClick={makeAPayment} fullWidth variant="primary" disabled={errorState}>
                Make A Payment
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

AccountSummary.propTypes = {
  redirectToCiti: PropTypes.func.isRequired,
  errorState: PropTypes.bool,
  isMobile: PropTypes.bool,
  account: PropTypes.shape({
    accountType: PropTypes.string,
    balance: PropTypes.string,
    minimumDue: PropTypes.string,
    dueDate: PropTypes.string,
    currentBalance: PropTypes.string,
    availableCredit: PropTypes.string,
    creditLimit: PropTypes.string,
    totalOpenInvoiceCount: PropTypes.string,
  }),
};
AccountSummary.defaultProps = {
  errorState: false,
  isMobile: false,
  account: {
    accountType: 'PLCN_HOMEDEPOT',
    balance: null,
    minimumDue: null,
    dueDate: null,
    currentBalance: null,
    availableCredit: null,
    creditLimit: null,
    totalOpenInvoiceCount: null,
  },
};

export default AccountSummary;
