import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  TextField,
  InputAdornment
} from '@one-thd/sui-atomic-components';
import { Search } from '@one-thd/sui-icons';
import * as utils from '../cardholder-utils';

function AllLinkedCardsModal({ open, handleClose, accounts, changeAccountHandler }) {
  const [cards, setCards] = useState(accounts);
  const [orderBy, setOrderBy] = useState('desc');

  function handleClickAccount(account) {
    changeAccountHandler(account);
    handleClose(true);
  }

  function sortBy() {
    setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    if (orderBy === 'desc') {
      const cardsSorted = utils.prioritizeAndAlphabeticallySortCards(accounts);
      setCards(cardsSorted);
      return;
    }
    setCards(accounts);
  }

  function search(value) {
    if (value?.length > 0) {
      const filtered = accounts.filter(
        (item) => item.accountName?.toUpperCase().includes(value.toUpperCase())
          || (item.accountNumber + '').includes(value)
      );
      setCards(filtered);
    } else {
      setCards(accounts);
    }
  }

  function getCommercialAccounts() {
    return cards?.filter(
      (item) => item.accountType === 'PLCR_HOMEDEPOT' || item.accountType === 'PLNP_HOMEDEPOT'
    );
  }

  const modalAccount = (account) => {
    return (
      <div
        onClick={() => handleClickAccount(account)}
        data-testid="accounts-item-button"
        className="sui-flex sui-items-center sui-justify-between hover:sui-bg-subtle"
        role="button"
        tabIndex={0}
      >
        {utils.renderAccount(account, false)}
      </div>
    );
  };

  const label = { inputProps: { 'aria-label': 'description' } };
  return (
    <Modal size="lg" open={open} onClose={handleClose}>
      <ModalHeader onClose={handleClose}>All Linked Cards</ModalHeader>
      <div className="sui-border-b-1 sui-border-solid sui-border-button-inactive" />
      <ModalBody>
        <div className="sui-w-full">
          <TextField
            fullWidth
            placeholder="Filter by Nickname or Last 4 Digits"
            onChange={(event) => search(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="subtle" />
                </InputAdornment>
              ),
              ...label
            }}
          />
        </div>
        <div className="sui-flex sui-my-4">
          <div className="sui-mr-4">Commercial Accounts ({getCommercialAccounts().length})</div>
          <button onClick={sortBy} className="sui-text-subtle" type="button">
            Sorted By A to Z
          </button>
        </div>
        <div className="sui-overflow-y-hidden">
          {cards.map((account, index) => {
            return (
              <div
                className="accounts-item sui-border-t-strong sui-border-t-1 sui-border-solid sui-cursor-pointer"
                data-testid="accounts-item"
                key={account?.accountId}
                id={account?.accountId}
              >
                {modalAccount(account)}
              </div>
            );
          })}
        </div>
      </ModalBody>
    </Modal>
  );
}

AllLinkedCardsModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountName: PropTypes.string,
      accountNumber: PropTypes.string,
      accountType: PropTypes.string,
      displayAccountNumber: PropTypes.string,
      parentAccountIndicator: PropTypes.string,
    })
  ),
  changeAccountHandler: PropTypes.func,
};

AllLinkedCardsModal.defaultProps = {
  open: false,
  accounts: [],
  changeAccountHandler: () => {},
  handleClose: () => {},
};

export default AllLinkedCardsModal;
