/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Typography
} from '@one-thd/sui-atomic-components';
import { CARD_IMAGE, CARD_NAME } from '../../utils/constants';

const UnlinkAccountModal = ({ modalClose, unlinkAccount, isMobile, accountsType }) => {
  return (
    <Modal open onClose={modalClose} size={isMobile ? 'lg' : 'md'}>
      <ModalHeader onClose={modalClose}>
        <Typography variant="h4" weight="display">
          Unlink Accounts?
        </Typography>
      </ModalHeader>
      <ModalBody data-component="UnlinkAccountModal">
        <>
          <div
            // eslint-disable-next-line
            className="sui-height-auto sui-pt-[1rem] sui-pl-[0.3rem] sui-pr-[0.3rem] sui-flex sui-relative sui-flex-col sui-break-words sui-bg-primary sui-bg-clip-border sui-grow sui-items-center sui-justify-center sui-text-center"
            role="button"
            tabIndex={0}
            id={accountsType}
            data-testid={accountsType}
            data-automation-id={accountsType}
          >
            <img
              src={CARD_IMAGE[accountsType]}
              alt="Apply for Home Depot Card"
              title="Apply for Home Depot Card"
              className={isMobile ? 'sui-pt-10' : ''}
              width="116"
              height="74"
            />
            <div className="sui-pt-5 sui-pb-4" data-testid="unlink-card-name">
              <Typography variant="h4" weight="display">
                {CARD_NAME[accountsType]}
              </Typography>
            </div>
          </div>
          <div data-testid="unlink-modal-description" className={` ${isMobile ? '' : 'sui-px-12' }`}>
            {`Unlinking accounts will remove your ${CARD_NAME[accountsType]} information from your
            Home Depot profile. You'll still be able to access your card details through the Credit
            Center or Citibank.`}
          </div>
        </>
      </ModalBody>
      <ModalFooter
        data-testid="unlink-modal-footer"
        className={`sui-pt-8 sui-px-6 sui-bg-primary sui-flex sui-justify-end ${
          isMobile ? 'sui-flex-col-reverse' : ''
        }`}
      >
        <div
          data-testid="unlink-cancel-container"
          className={` ${isMobile ? '' : 'sui-w-[10.5rem] '}  sui-shrink-0 sui-p-[1rem]`}
        >
          <Button
            variant="secondary"
            disabled={false}
            fullWidth={!!isMobile}
            onClick={modalClose}
            id="unlink-modal-cancel"
            data-testid="unlink-modal-cancel"
          >
            Cancel
          </Button>
        </div>
        <div
          data-testid="unlink-apply-container"
          className={` ${isMobile ? '' : 'sui-w-[22.5rem]'}  sui-shrink-0 sui-p-[1rem]`}
        >
          <Button
            variant="primary"
            id="apply-button"
            data-testid="unlink-apply-button"
            onClick={unlinkAccount}
            fullWidth
            disabled={false}
          >
            Unlink Accounts
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

UnlinkAccountModal.propTypes = {
  modalClose: PropTypes.func.isRequired,
  unlinkAccount: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  accountsType: PropTypes.string.isRequired,
};

export default UnlinkAccountModal;
