/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '~/@one-thd/sui-atomic-components';
import { Info } from '~/@one-thd/sui-icons';

export const PromptMessage = ({
  cardImage, title, description, viewDetails, payWithHomeDepot, isB2B
}) => {
  return (
    <>
      <div
        className="sui-border-solid sui-border-1
          sui-border-primary sui-p-4"
        data-testid="checkout-prompt-message"
      >
        <div className="sui-grid sui-grid-cols-3 sui-gap-x-16">
          <div className="sui-col-span-2">
            <div className="sui-ml-0 sui-mr-8 sui-flex sui-items-center">
              <img src={cardImage} alt="Credit Card Icon" width="40" height="20" />
              <div className="sui-ml-3">
                <Typography variant="h5">{title}</Typography>
              </div>
            </div>
            <div className="sui-pt-1 sui-flex">
              <Typography variant="body-base">
                <span data-testid="checkout-prompt-description">
                  {description}
                  <span className="sui-cursor-pointer sui-relative sui-top-2px">
                    <Info data-testid="InfoIcon" color="info" size="small" onClick={viewDetails} />
                  </span>
                </span>
              </Typography>
            </div>
          </div>
          <div className="sui-ml-5 sui-w-36 sui-justify-self-end">
            <Link target="_blank" onClick={payWithHomeDepot} data-testid="pay-with-home-depot-card">
              {isB2B ? 'Pay With My Pro Xtra Credit Card' : 'Pay With My Home Depot Card'}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

PromptMessage.propTypes = {
  cardImage: PropTypes.string,
  title: PropTypes.node,
  description: PropTypes.node,
  viewDetails: PropTypes.func.isRequired,
  payWithHomeDepot: PropTypes.func.isRequired,
  isB2B: PropTypes.bool.isRequired
};

PromptMessage.defaultProps = {
  cardImage: '',
  title: '<p>Pay $XXX.XX per Month When You Use Your Card<p>',
  /*  eslint-disable-next-line max-len */
  description:
    '<p>$XXX.XX per month* suggested payments with 6 months financing on this $XXX.XX qualifying purchase.<p>',
};
