import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Typography, Link } from '@one-thd/sui-atomic-components';
import { REDIRECT_TEXT } from '../utils/constants';

const ErrorAlert = ({ error, redirectToCiti, accountType }) => {

  const errorMessageRedirect = () => {
    redirectToCiti(null, null);
  };

  if (error && error.errorMessage) {
    const closeText = error.errorCode ? REDIRECT_TEXT[error.errorCode] : null;
    const onClose = error.errorCode ? errorMessageRedirect : null;
    return (
      <Alert status="error">
        <div className="sui-flex sui-gap-1 sui-flex-wrap">
          <Typography>{error.errorMessage}</Typography>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link onClick={onClose} target="_blank">
            {closeText}
          </Link>
        </div>
      </Alert>
    );
  }
  return null;
};

ErrorAlert.propTypes = {
  error: PropTypes.shape({
    errorMessage: PropTypes.string,
    errorCode: PropTypes.string,
  }).isRequired,
  redirectToCiti: PropTypes.func.isRequired,
  accountType: PropTypes.string,
};

ErrorAlert.defaultProps = {
  accountType: 'PLCN_HOMEDEPOT',
};

export default ErrorAlert;
