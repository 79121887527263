/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import * as constants from '../../utils/constants';

const AdditionalServices = ({
  accountType,
  redirectToCiti,
  isSaveCardButtonEnabled,
  isMobile,
  errorState
}) => {
  const processIndicators = constants.PROCESS_INDICATORS;

  const onSaveCardToPaymentMethodHandler = () => {
    if (
      window.sessionStorage.getItem('ecmSaveCardInitiation')
      && sessionStorage.getItem('ecmSaveCardInitiation') !== 'exisitingSsoUser'
    ) {
      sessionStorage.setItem('ecmSaveCardInitiation', 'exisitingSsoUser');
    }
    redirectToCiti(processIndicators.SAVE_CARD, 'plcc:save card', 'ecm=true');
  };

  const setPhoneNumbers = (account) => {
    if (account === constants.PLCN_HOMEDEPOT) {
      return '1-800-677-0232';
    }
    if (account === constants.PLNP_HOMEDEPOT) {
      return '1-800-395-7363';
    }
    if (account === constants.PLCR_HOMEDEPOT) {
      return '1-800-685-6691';
    }
    return '';
  };

  return (
    <>
      <div className="sui-w-full sui-grid sui-grid-cols-1 md:sui-grid-cols-2 lg:sui-grid-cols-2 sui-mt-10 sui-gap-8 ">
        <div
          data-testid="credit-card-faq-container"
          className={`${isMobile ? 'sui-mt-10 sui-order-2' : 'sui-order-1'}  `}
        >
          <div className="sui-pb-4">
            {isMobile ? (
              <Typography variant="h5" weight="bold">
                <div className="sui-flex sui-items-center">
                  <span>Home Depot Credit Card FAQ</span>
                </div>
              </Typography>
            ) : (
              <>
                <Typography className="sui-header" variant="h3" weight="display">Home
                  Depot
                </Typography>
                <Typography className="sui-header" variant="h3" weight="display">Credit Card
                  FAQ
                </Typography>
              </>
            )}
          </div>
          <div className="sui-pb-2">
            <Link href="https://www.homedepot.com/c/Credit_Center">
              Credit Services
            </Link>
          </div>
          <div className="sui-pb-2">
            <Link
              onClick={() => redirectToCiti(
                processIndicators.FAQ,
                'plcc:FAQ Articles'
              )}
            >
              FAQ Articles
            </Link>
          </div>
          <div className="sui-pb-2">
            <Link
              onClick={() => redirectToCiti(
                processIndicators.HELP_AND_CONTACT,
                'plcc:Help and Contact'
              )}
            >
              Help and Contact
            </Link>
          </div>
          <div className="sui-pb-2">
            {isMobile ? (
              <Link href={'tel:' + setPhoneNumbers(accountType)}>
                Call us at {setPhoneNumbers(accountType)}
              </Link>
            ) : `Call us at ${setPhoneNumbers(accountType)}`}
          </div>
          <div className="sui-pb-2">
            TTY: 711
          </div>
        </div>
        {!errorState && (
          <div className={`${isMobile ? 'sui-mt-10 sui-order-1' : 'sui-order-2'}  `}>
            <div>
              <div className="sui-pb-4">
                {isMobile ? (
                  <Typography variant="h5" weight="bold">
                    Additional Services
                  </Typography>
                ) : (
                  <>
                    <Typography
                      className="sui-header"
                      variant="h3"
                      weight="display"
                    >
                      Additional
                    </Typography>
                    <Typography
                      className="sui-header"
                      variant="h3"
                      weight="display"
                    >Services
                    </Typography>
                  </>
                )}
              </div>
              {isSaveCardButtonEnabled && accountType === constants.PLCN_HOMEDEPOT && (
                <div className="sui-pb-2">
                  <Link onClick={onSaveCardToPaymentMethodHandler}>
                    Save card to My Payment Methods
                  </Link>
                </div>
              )}
              <div className="sui-pb-2">
                <Link
                  onClick={() => redirectToCiti(
                    processIndicators.MANAGE_PAYMENT_SOURCES,
                    'plcc:Manage Payment Sources'
                  )}
                >
                  Manage Payment Sources
                </Link>
              </div>
              {!(accountType === constants.PLNP_HOMEDEPOT) && (
                <div className="sui-pb-2">
                  <Link
                    onClick={() => redirectToCiti(
                      processIndicators.ENROLL_AUTO_PAY,
                      'plcc:enroll in auto-pay'
                    )}
                  >
                    Enroll in Auto-Pay
                  </Link>
                </div>
              )}
              <div className="sui-pb-2">
                <Link
                  onClick={() => redirectToCiti(
                    processIndicators.PAPERLESS_SETTINGS,
                    'plcc:Manage Paperless Settings'
                  )}
                >
                  Manage Paperless Settings
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
AdditionalServices.propTypes = {
  accountType: PropTypes.string.isRequired,
  redirectToCiti: PropTypes.func.isRequired,
  isSaveCardButtonEnabled: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  errorState: PropTypes.bool,
};

AdditionalServices.defaultProps = {
  isMobile: false,
  errorState: false
};

export default AdditionalServices;
