import React, { useEffect } from 'react';
import './save-card-modal.style.scss';
import { Modal, ModalHeader, ModalBody, Button } from '@one-thd/sui-atomic-components';
import { ArrowForward } from '@one-thd/sui-icons';
import PropTypes from 'prop-types';
import THDCustomer from '@thd-olt-global/thd-customer';
import { CARD_IMAGE, CARD_IMAGE_TESTID } from '../utils/constants';
import { modalImpressionEvent } from '../utils/publisher';

const isCartUrl = (url) => url.indexOf('cart') >= 0;

/* eslint-disable @mizdra/layout-shift/require-size-attributes */
export const SaveCardSuccessModal = ({ closeModal }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const cardType = urlParams.get('cardType') || 'PLCN_HOMEDEPOT';
  const decodedOriginUrl = urlParams.get('originUrl')
    ? decodeURIComponent(urlParams.get('originUrl')) : 'https://www.homedepot.com';
  const username = THDCustomer.username ? `${THDCustomer.username},` : '';
  const isCartOriginUrl = isCartUrl(decodedOriginUrl);
  let buttonName = isCartOriginUrl ? 'Review Cart' : 'Continue Shopping';

  const startShoppingHandler = () => {
    window.location.replace(decodedOriginUrl);
  };

  const selectCardName = {
    PLNP_HOMEDEPOT: 'Commercial Revolving Card',
    PLCR_HOMEDEPOT: 'Pro Xtra Card',
    PLCN_HOMEDEPOT: 'Consumer Card'
  };

  useEffect(() => {
    modalImpressionEvent(`your ${selectCardName[cardType]} has been saved`);
  }, []);

  return (
    <Modal
      closeButton
      open
      onClose={closeModal}
      size="md"
    >
      <ModalHeader onClose={closeModal}>
        <p className="sui-font-display sui-font-condensed sui-text-3xl" data-testid="success-title">
          {username} Your card has been saved!
        </p>
      </ModalHeader>
      <ModalBody data-testid="success-sec" data-component="SaveCardSuccessModal">
        <img
          data-testid={CARD_IMAGE_TESTID[cardType]}
          src={CARD_IMAGE[cardType]}
          alt="creditcard"
          className="save-card-modal-image"
        />
        <p className="sui-text-base sui-p-3 sui-mb-5">
          Your {selectCardName[cardType]} has been saved to your account.
          You can now place orders online with your card before it arrives in the mail.
          You can now manage your saved payment methods in your My Account profile.
        </p>
        <Button
          data-testid="start-shopping"
          onClick={startShoppingHandler}
          fullWidth
          endIcon={ArrowForward}
        >
          {buttonName}
        </Button>
      </ModalBody>
    </Modal>
  );
};

SaveCardSuccessModal.propTypes = {
  closeModal: PropTypes.func.isRequired
};
