import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography, Popover, IconButton } from '@one-thd/sui-atomic-components';
import { InfoFilled } from '@one-thd/sui-icons';
import { useHeaderObserver } from '@thd-olt-functional/utils';

export const InfoToolTip = ({ message, title }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [entry] = useHeaderObserver();

  const bottomStyles = {
    paddingBottom: entry?.navigation?.positionBottom && entry?.visible ? entry?.navigation.clientRect?.height : 0
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="sui-z-10">
      <IconButton aria-describedby={id} onClick={handleClick} icon={InfoFilled} color="info" />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="bottom"
        title={title}
      >
        <div style={bottomStyles}>
          <Typography variant="body-xs">{message}</Typography>
        </div>
      </Popover>
    </div>
  );
};

InfoToolTip.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
