/* eslint-disable max-len */
/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import PropTypes from 'prop-types';
import { Button, Typography } from '@one-thd/sui-atomic-components';
import { CreditCard } from '@one-thd/sui-icons';
import { CARD_IMAGE, NON_CARD_HOLDER_VIEW } from '../utils/constants';
import { modalClickEvent } from '../utils/publisher';
import UnlinkedAlert from '../CardholderView/unlinked-alert.component';

const NonCardholderView = ({
  disableSSO, linkAccount, payAndManage, redirectToCiti, unlinkedAccounts
}) => {
  const handlePayAndMange = () => {
    modalClickEvent(NON_CARD_HOLDER_VIEW.PAY_MANAGE);
    payAndManage();
  };

  const handleLinkAccount = () => {
    modalClickEvent(NON_CARD_HOLDER_VIEW.LINK_ACCOUNT);
    linkAccount();
  };

  const experienceContext = useContext(ExperienceContext);
  const { channel } = experienceContext;
  const isMobile = channel === 'mobile';
  return (
    <>
      <div className="sui-p-[1rem]" data-component="NonCardholderView">
        <UnlinkedAlert
          unlinkedAccounts={unlinkedAccounts}
          redirectToCiti={redirectToCiti}
          linkAccount={linkAccount}
        />
        <div className="sui-mt-[2.5rem] sui-mb-[1rem] sui-flex">
          <span className="sui-text-7xl sui-text-primary sui-font-display">
            <Typography variant="h2" weight="display">
              Two Ways to Pay & Manage Your Card
            </Typography>
          </span>
        </div>
        <div className={`${isMobile ? 'sui-block' : 'sui-flex'}   sui-pb-[3rem] sui-pt-[2.5rem]`}>
          <div className="sui-basis-1/2">
            <div className={`sui-flex sui-items-center ${isMobile ? 'sui-mb-12' : 'sui-mb-10'}`}>
              <img
                src="https://assets.homedepot-static.com/images/v1/hd-logo.svg"
                className="sui-p-[1rem]"
                alt="Home Depot Logo"
                width="95px"
                height="95px"
                data-testid="home-depot-logo"
              />
              <img
                src="https://assets.homedepot-static.com/images/v1/link.svg"
                className="sui-mr-6 sui-ml-6"
                alt="Link Icon"
                width="30px"
                height="30px"
                data-testid="link-logo"
              />
              <div className="card-image-container sui-relative sui-p-[1rem]">
                <img
                  src={CARD_IMAGE.PLNP_HOMEDEPOT}
                  alt="PROX Credit Card"
                  className="sui-w-[11.2rem] sui-h-[7.0rem]"
                  width="112px"
                  height="70px"
                />
                <img
                  src={CARD_IMAGE.PLCR_HOMEDEPOT}
                  alt="Pro Xtra Card"
                  className="sui-w-[11.2rem] sui-h-[7.0rem] sui-absolute sui-top-[2rem] sui-left-[3rem]"
                  width="112px"
                  height="70px"
                />
                <img
                  src={CARD_IMAGE.PLCN_HOMEDEPOT}
                  alt="Consumer Credit Card"
                  className="sui-w-[11.2rem] sui-h-[7.0rem] sui-absolute sui-top-[3rem] sui-left-[5rem]"
                  width="112px"
                  height="70px"
                />
              </div>
            </div>
            <p className="sui-font-display sui-font-bold  sui-pb-4  sui-pt-[1rem] sui-text-lg">
              Pay & Manage From One Account
            </p>
            <div className="sui-leading-normal sui-pb-[1rem] sui-text-base">
              Want easier access to your Home Depot Credit Card information?
              <div className="sui-pt-2 sui-pb-4">Next visit, you&apos;ll be able to:</div>
              <ul className="sui-pt-[1rem] sui-list-disc sui-list-inside">
                <li className="sui-ml-[2rem] sui-pb-2 sui-font-bold"> View your card </li>
                <li className="sui-ml-[2rem] sui-pb-2 sui-font-bold"> Pay your balance </li>
                <li className="sui-ml-[2rem] sui-pb-2 sui-font-bold"> Manage your account </li>
                <li className="sui-ml-[2rem] sui-pb-2 sui-font-bold"> View Consumer Card Special Offers </li>
              </ul>
            </div>
            <p className="sui-leading-normal sui-pb-6  sui-text-base">
              <span className="sui-font-bold">New cardholder?</span> Wait until you receive your
              card in the mail before linking accounts.
            </p>
            <Button
              variant="primary"
              disabled={disableSSO}
              fullWidth
              onClick={handleLinkAccount}
              data-testid="link_pay"
              data-automation-id="link_pay"
            >
              Link & Pay Card
            </Button>
          </div>
          <div className={isMobile ? '' : 'sui-ml-[4rem] sui-border-solid sui-border-l-1 sui-border-primary   sui-pl-[4rem] sui-basis-1/2'}>
            <p className={`${isMobile ? 'sui-pt-12 sui-pb-4' : 'sui-pt-[1rem] sui-pb-4'} sui-font-display sui-font-bold sui-leading-normal sui-text-lg`}>
              Sign in with your Credit Card Account
            </p>
            <p className="sui-leading-normal sui-pb-6  sui-text-base">
              Not ready to link accounts? Access your credit card information on the Citibank
              website instead. You can always link accounts later in My Account.
            </p>
            <Button
              variant="secondary"
              fullWidth
              onClick={handlePayAndMange}
              data-testid="pay_manage"
              data-automation-id="pay_manage"
            >
              Pay & Manage Without Linking
            </Button>
            <p className="sui-pt-4 sui-text-xs">
              By clicking Pay & Manage, you will be re-directed to a Citibank owned site.
            </p>
          </div>
        </div>
        <div className="sui-p-[2rem] sui-text-center">
          <div className=" sui-flex sui-items-center sui-justify-center sui-pb-[2rem]">
            <CreditCard size="large" />
          </div>
          <div className="sui-font-display sui-font-bold sui-text-lg">
            Don&apos;t have a Home Depot Credit Card?
          </div>
          <p className={`sui-max-w-[71rem] sui-pt-[1rem] sui-pb-[2rem] sui-leading-normal sui-ml-auto sui-mr-auto  sui-text-base ${isMobile ? 'sui-text-left' : ''}`}>
            Whether it&apos;s for home or for business, The Home Depot finance and card services
            team has you covered with a store card or Home Depot loan to help you get the job done.
          </p>
          <a
            className="sui-underline  sui-text-base"
            href="https://www.homedepot.com/c/Credit_Center"
          >
            Explore Home Depot Credit Cards
          </a>
        </div>
      </div>
    </>
  );
};

NonCardholderView.propTypes = {
  disableSSO: PropTypes.bool.isRequired,
  linkAccount: PropTypes.func.isRequired,
  payAndManage: PropTypes.func.isRequired,
  redirectToCiti: PropTypes.func.isRequired,
  unlinkedAccounts: PropTypes.arrayOf(PropTypes.shape({
    accountType: PropTypes.string,
  })),
};

NonCardholderView.defaultProps = {
  unlinkedAccounts: []
};

export default NonCardholderView;
