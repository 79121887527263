import React from 'react';
import * as constants from '../utils/constants';
import { MenuItem } from '~/@one-thd/sui-atomic-components/dist/index';

export const filterUnlinkedAccounts = (accounts) => {
  return accounts.reduce(
    ([linked, unlinked], account) => {
      const isUnlinked = account.errors && account.errors.errors[0].errorCode === 'SSO_CITI_401';
      return isUnlinked ? [linked, [...unlinked, account]] : [[...linked, account], unlinked];
    },
    [[], []]
  );
};

export const sortAccountsForDefault = (account1, account2) => {
  if (account1.defaultCard === undefined && account2.defaultCard === undefined) {
    return 0;
  }
  // Display default consumer over commercial
  if (account1.defaultCard === true && account2.defaultCard === true) {
    if (account1.accountType === 'PLCN_HOMEDEPOT') {
      return -1;
    }
    if (account2.accountType === 'PLCN_HOMEDEPOT') {
      return 1;
    }
    if (account1.accountType === 'PLCR_HOMEDEPOT') {
      return -1;
    }
    return 1;
  }
  if (account1.defaultCard === true) {
    return -1;
  }
  if (account2.defaultCard === true) {
    return 1;
  }
  return 0;
};

export const getAccountToBeUpdated = (data = { accounts: [] }) => {
  let acctToBeUpdated;
  if (data && data.accounts && data.accounts.length > 0) {
    [acctToBeUpdated] = data.accounts.filter(
      (account) => account.accountType === data.updateAccountType
    );
  }
  return acctToBeUpdated || {};
};

export const getUpdatedAccountList = (data = {}, accList = []) => {
  const accountsList = [...accList];
  const acctToBeUpdated = getAccountToBeUpdated(data);
  const updateIdx = accountsList.findIndex(
    (account) => account.accountId && account.accountId === acctToBeUpdated.accountId
  );
  if (updateIdx >= 0) accountsList[updateIdx] = acctToBeUpdated;
  return accountsList;
};

export const formatCurrency = (amount) => {
  if (!amount) {
    return '---';
  }
  if (amount.slice(0, 1) === '-') {
    return '-$' + amount.slice(1);
  }
  return '$' + amount;
};

/* eslint-disable id-length */

export const sortCrcAndProxCardsAlphabetically = (a, b) => {
  const firstCardSortingCriteria = a.accountName || 'Commercial Revolving';
  const secondCardSortingCriteria = b.accountName || 'Commercial Revolving';
  return firstCardSortingCriteria.localeCompare(secondCardSortingCriteria);
};

const filterCardsForConsumerCards = (card) => card.accountType === constants.PLCN_HOMEDEPOT;
const filterCardsForCrcCards = (card) => card.accountType === constants.PLCR_HOMEDEPOT;
const filterCardsForProxCards = (card) => card.accountType === constants.PLNP_HOMEDEPOT;
const filterCardsForProxChildrenAccounts = (card) => card.parentAccountIndicator === 'Y';
const filterCardsForProxNonChildrenAccounts = (card) => card.parentAccountIndicator === 'N';
const filterCardsForProxErrors = (card) => typeof card.errors === 'object';

export const sortCardListForDefaultConsumerCard = (a, b) => {
  if (a.defaultCard === undefined && b.defaultCard === undefined) {
    return 0;
  }
  if (a.defaultCard === true) {
    return -1;
  }
  if (b.defaultCard === true) {
    return 1;
  }
  return 0;
};

export const prioritizeAndAlphabeticallySortCards = (cardData) => {
  const consumerCardsSortedByDefault = cardData
    .filter(filterCardsForConsumerCards)
    .sort(sortCardListForDefaultConsumerCard);
  const crcCardsSortedAlphabetically = cardData
    .filter(filterCardsForCrcCards)
    .sort(sortCrcAndProxCardsAlphabetically);
  const proxCards = cardData.filter(filterCardsForProxCards);
  const proxCardsWithChildAccountsSortedAlphabetically = proxCards
    .filter(filterCardsForProxChildrenAccounts)
    .sort(sortCrcAndProxCardsAlphabetically);
  const proxCardsWithoutChildAccountsSortedAlphabetically = proxCards
    .filter(filterCardsForProxNonChildrenAccounts)
    .sort(sortCrcAndProxCardsAlphabetically);
  const proxCardsWithErrors = proxCards.filter(filterCardsForProxErrors);
  // exists to support v1 of account snapshot API which returns single field for Prox Cards
  const singleFieldProxCards = proxCards.filter((card) => Object.keys(card).length === 1);

  return [
    ...consumerCardsSortedByDefault,
    ...crcCardsSortedAlphabetically,
    ...proxCardsWithChildAccountsSortedAlphabetically,
    ...proxCardsWithoutChildAccountsSortedAlphabetically,
    ...proxCardsWithErrors,
    ...singleFieldProxCards,
  ];
};

export const generateCreditCardLabel = (card) => {
  if (card && card.accountName) {
    return card.accountName.toUpperCase();
  }
  return constants.CARD_NAME[card.accountType].toUpperCase();
};

export const getCreditCardNumberText = (card, fromDropdown = true) => {
  if (card && card.errors) return '---';
  if (card && (card.accountNumber || card.displayAccountNumber)) {
    return card.accountType === constants.PLNP_HOMEDEPOT
      && card.parentAccountIndicator === 'Y'
      && fromDropdown
      ? `••••${card.accountNumber || card.displayAccountNumber} | Has Child Acct.`
      : `••••${card.accountNumber || card.displayAccountNumber}`;
  }
  return 'Account Linked';
};

export const renderAccount = (account, fromDropdown = true) => {
  return (
    <>
      <div className="sui-flex sui-items-center">
        <img
          src={constants.CARD_IMAGE[account?.accountType]}
          alt="Credit Card Icon"
          className="sui-h-8"
          width=""
          height="30"
        />
        <div className="sui-text-left sui-p-2">
          <div>{generateCreditCardLabel(account)}</div>
          <div>{getCreditCardNumberText(account, fromDropdown)}</div>
        </div>
      </div>
      {!fromDropdown && account?.childAccounts?.length && (
        <div className="sui-text-right sui-text-subtle has-child">Has child accounts</div>
      )}
    </>
  );
};
